@import "../../../scss/variables";

.DocumentsDownload {
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 15px;
  background-color: map-get($theme-colors, "lightgray");

  .row {
      margin: 0 px;
      padding: 10px 10px;
      text-align: left;
      word-break: break-all;
   }

    svg path {
      fill: map-get($theme-colors, "blue");
    }

    .icon {
      margin-right: 10px;
    }

    font-size: 1rem;
}