/* ---------------------------------------------------
    STEP INDICATOR STYLES
----------------------------------------------------- */

@import '../../_variables';

.progress-indicator {

  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  margin: auto;

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    i {
      margin: 0px 5px;

      &.circle-completed svg {
        background-color: map-get($theme-colors, "green");
        border-radius: 50%;
        opacity: 0.6;

        path:nth-of-type(2) {
          fill: map-get($theme-colors, "green");
        }
      }

    }

  }


  hr {
    border: 0.5px solid map-get($theme-colors, "mediumgray");
    width: 100%;
    opacity: 0.6;
  }

  .step:first-of-type {
    hr:first-of-type {
      border-color: transparent;
    }
  }

  .step:last-of-type {
    hr:last-of-type {
      border-color: transparent;
    }
  }

  .step>div {
    width: 100%;
  }

  .step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
  }

  .circle {
    border: 3px solid #fff;
    background-color: map-get($theme-colors, "mediumgray");
    border-radius: 50%;
    min-height: 12px !important;
    min-width: 12px !important;
    margin: 0px 5px;
  }

  .step-label {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 1rem;
  }

  .step.current {

    .circle {
      border-color: map-get($theme-colors, "primary");
      background-color: #fff;
    }

    i.circle-completed svg {
      opacity: 1;
    }

    .step-label {
      opacity: 1;
    }

  }

  .current hr:nth-of-type(2),
  .current+.step hr:first-of-type {
    opacity: 1;
  }

  .prior hr:nth-of-type(2),
  .prior+.step hr:first-of-type {
    opacity: 1;
  }

  .step:not(.current) i.circle-completed svg {
    opacity: 0.6;
  }


}
