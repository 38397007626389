@import "../../variables";

.BankContactList {
  .bank-contact-group {
    border-top: 1px solid map-get($theme-colors, "lightgray") !important;
  }

  .bank-contact-group-title::before {
    content: "Bank Contact";
    position: absolute;
    top: 170px;
    color: map-get($theme-colors, "primary");
    font-weight: normal;
  }

  .email-cc {
    margin-top: -1.5rem;
  }

  .search-form {
    margin-top: 1.5rem;

    #bank,
    label.floating-label.filter-dropdown,
    .form-control:focus + .floating-label.filter-dropdown {
      margin-left: 57px;
    }
  }
  .ml-15 {
    margin-left: 15px;
  }
}
