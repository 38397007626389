@import "../variables";

.bank-group {
  border-radius: 10px;
  padding: 20px;
  background-color: map-get($theme-colors, "verylightgray");
  width: 110%;
  margin-left: -5%;
  margin-bottom: 40px;
}

.icon-button {
  padding: 0 10px;
  margin-right: -10px;
}

.trust-account-number-input {
  letter-spacing: 1px;
}

.add-edit-client {
  input:read-only {
    background-color: #ffffff;
  }
  input:disabled {
    background-color: #ffffff;
  }
  select:disabled {
    background-color: #ffffff;
  }

  select.form-control {
    padding-left: 22px;
  }

  .save-draft {
    padding-right: 5px;
    width: 125px;
    float: right;
    text-align: center;
    text-align: center !important;
    margin-bottom: 10px;
  }
}

#clientNameId {
  top: 35px !important;
}

#captiveName {
  top: 35px !important;
}

#captiveCode {
  top: 35px !important;
}

#bankName {
  top: 35px !important;
}

input.form-control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .height-400-sm {
    height: 400px;
    overflow-y: scroll;
  }
}
