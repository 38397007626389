@import "../../../scss/variables";

.CessionsCalendar {
  cursor: pointer;
  font-size: 0.8rem;
  // width: 275px;

  .card-header {
    padding: 0.1em;
  }

  .card-body {
    padding: 0;

    .transfer-days {
      color: map-get($theme-colors, "green");
      padding-right: 0.5rem;
      font-weight: bold;
      font-size: 2rem;
    }

    p {
      text-align: justify;
    }
  }

  .cessions-tooltip {
    margin-right: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-right: 40px;
    visibility: hidden;
    width: 350px;
    background-color: map-get($theme-colors, "verylightgray");
    color: black;
    text-align: right;
    border-right: 3px solid map-get($theme-colors, "blue");
    position: absolute;
    left: -350px;
    top: 75px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;

    .transfers-block::after {
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      border-width: 10px;
      border-style: solid;
      border-color: map-get($theme-colors, "green")
        map-get($theme-colors, "green") map-get($theme-colors, "green")
        map-get($theme-colors, "green");
    }

    .blackout-block::after {
      content: "";
      position: absolute;
      bottom: 20px;
      right: 10px;
      border-width: 10px;
      border-style: solid;
      border-color: map-get($theme-colors, "darkgray")
        map-get($theme-colors, "darkgray") map-get($theme-colors, "darkgray")
        map-get($theme-colors, "darkgray");
    }
  }

  &:hover .cessions-tooltip {
    visibility: visible;
    opacity: 1;
  }

  /* Arrow for tooltip */
  .cessions-tooltip::after {
    content: "";
    position: absolute;
    top: 0%;
    left: 100%;
    margin-top: 10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent
      map-get($theme-colors, "blue");
  }
}
