/* ---------------------------------------------------
    VARIABLES
----------------------------------------------------- */

$font-family-sans-serif: "Open Sans", sans-serif;

$theme-colors: (
  "primary": #2f353a,
  "secondary": #ff9900,
  "danger": #d84747,
  "success": #39b64a,
  "darkgray": #44464b,
  "mediumgray": #95989a,
  "lightgray": #dbdbdd,
  "verylightgray": #f8f8f8,
  "bordergray": #7e7e7e,
  "blue": #0070b9,
  "green": #40b93c,
  "darkblue": #54698d,
  "black": #13223c,
  "verydarkgray": #252525,
);

$btn-font-weight: bold;

$btn-border-radius: 40px;
$btn-padding-y: 5px;
$btn-border-width: 2px;

$input-padding-y: 0.43rem;
$input-border-width: 1px;
$input-border-radius: 0;
$input-placeholder-color: theme-color("mediumgray");

$header-height: 50.2px;
$footer-height: 48px;
$sidebar-width: 250px;
$profile-menu-width: 250px;
$sidebar-border: 0.5px solid theme-color("darkgray");
$profile-menu-border-color: 0.5px solid #eee;

$enable-transitions: true;

$grid-breakpoints: (
  xxs: 0,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
