@import '../../variables';

/* ---------------------------------------------------
    TOPBAR STYLE
----------------------------------------------------- */
.topbar {

  .logo {
    max-width: 250px;
    padding: 5px 0px;

    & img {
      width: 72%;
    }
  }

  .toggle-menu:hover {
    cursor: pointer;
  }

  .toggle-profile-menu {
    position: absolute;
    right: 0px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .topbar-user {

    position: absolute;
    right: 50px;

    & .profile-link {
      border-left: .5px solid #eee;
    }


    .notification {

      i {
        display: flex;
      }

      &:hover {
        cursor: pointer;
      }

      .badge {
        position: absolute;
        left: 13px;
        top: -4px;
        font-size: 12px;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #1575C5;
        color: #1575C5;
      }

    }

    .profile-image {
      height: 30px;
      width: 30px;
      font-size: 15px;
      margin-right: 10px;
    }
  }


  @media only screen and (max-width: map-get($grid-breakpoints, md)) {

    .logo img {
      margin-left: 30px;
    }


  }

  @media only screen and (max-width: map-get($grid-breakpoints, sm)) {

    .topbar-user {

      .profile-image {
        margin-right: -10px;
      }

      .profile-name {
        display: none;
      }

    }

  }

}
