@import '../../variables';

.PayeeAccountsList {
    .payee-account-container {
        margin: 0px auto;
        padding: 1rem;
        padding: 0;
    }
}

.payee-account-item {
    width: 410px;
    padding: 1rem;
    margin: 0.5rem 0rem;
    border: 1px dashed map-get($theme-colors, "lightgray");
    border-radius: 5px;
    box-shadow: 3px 3px map-get($theme-colors, "lightgray");
    list-style-type: none;
    cursor: grab;
    background-color: map-get($theme-colors, "verylightgray");
}
