@import "../../variables";

.toggle-button {
  display: inline-flex;

  .toggle-container {
    width: 30px;
    height: 20px;
    border-radius: 20px;
    border: none;
    padding: 0;
    position: relative;

    .toggle-button {
      border-radius: 50%;
      height: 20px;
      width: 20px;
      padding: 0;
      border-width: 2px;
      border-style: solid;
      position: absolute;
      background-color: #fff;
      transition: .5s ease all;
    }

  }

  &.off {

    .toggle-container {
      background-color: map-get($theme-colors, "lightgray");

      .toggle-button {
        border-color: #000;
        left: 0;
      }
    }
  }

  &.on {
    font-weight: bold;

    .toggle-container {
      background-color: map-get($theme-colors, "green");

      .toggle-button {
        right: 0;
        border-color: map-get($theme-colors, "green");
      }
    }
  }

  .toggle-state {
    padding: 0;
    margin-left: 10px;
  }

}
