@import "../../_variables";

.transaction-status-indicator {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  margin: auto;
  margin-top: 6px;
  cursor: pointer;

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }

  hr {
    border: 0.5px solid map-get($theme-colors, "mediumgray");
    width: 100%;
  }

  .step:first-of-type {
    hr:first-of-type {
      border-color: transparent;
    }
  }

  .step:last-of-type {
    hr:last-of-type {
      border-color: transparent;
    }
  }

  .step > div {
    width: 100%;
  }

  .step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12px;
  }

  .circle-completed {
    border: 3px solid map-get($theme-colors, "green");
    background-color: map-get($theme-colors, "green");
    border-radius: 50%;
    min-height: 12px !important;
    min-width: 12px !important;
    margin: 0px 5px;
    position: relative;
    display: inline-block;
  }

  .circle-completed::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 1px;
    background-color: map-get($theme-colors, "verylightgray");
    content: "";
    transform: translateX(2px) rotate(-45deg);
    transform-origin: left bottom;
  }

  .circle-completed:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: map-get($theme-colors, "verylightgray");
    content: "";
    transform: translateX(2px) rotate(-45deg);
    transform-origin: left bottom;
  }

  .circle {
    border: 3px solid #fff;
    background-color: map-get($theme-colors, "mediumgray");
    border-radius: 50%;
    min-height: 12px !important;
    min-width: 12px !important;
    margin: 0px 5px;
  }

  .step.error {
    .circle {
      border-color: map-get($theme-colors, "danger");
      background-color: map-get($theme-colors, "verylightgray");
    }
  }

  .step.current {
    .circle {
      border-color: map-get($theme-colors, "primary");
      background-color: map-get($theme-colors, "verylightgray");
    }
  }
}
