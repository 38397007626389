/* ---------------------------------------------------
    INVITE USERS STYLES
----------------------------------------------------- */

@import '../_variables';

.invite-user-body {
  height: 80vh;
}

.addUser {
  display: flex;

  .nav-item {
    cursor: pointer;
  }


  .tables {
    height: 500px;
  }

  .MyProfile-header {
    flex: 1 1;
  }

  .table {
    border: 0px;
  }

  .card-one {
    flex: 2;

    form {
      flex: 1;
    }

  }

  .card-two {
    flex: 3;
  }

  .trash-btn {
    border: 0px;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
  }
}

.searchUser {
  .scrollable {
    max-height: 90%;
  }

  input.form-control {
    padding-right: 0px;
  }

  .form-icon {
    margin-right: 7px;
  }

  .table thead th {
    border-top-width: 0px;
    border-bottom-width: 1px;
  }

  .table thead th:first-child {
    border-left-width: 0px;
  }

  .table thead th:last-child {
    border-right-width: 0px;
  }

  .table tbody td:first-child {
    border-left-width: 0px;
  }

  .table tbody td:last-child {
    border-right-width: 0px;
  }
}

.scrollable-list {
  max-height: 550px;
  overflow: auto;
}

.userPermissions {
  .PermissionMatrix {
    thead {
      td {
        cursor: pointer;
      }
    }

    td {
      text-align: center;
      width: 210px;
    }

    .selected {
      border: 0px;

      border-right: solid 1px #1575C5 !important;
      border-left: solid 1px #1575C5 !important;
      border-bottom: solid 1px #dee2e6 !important;

      &.last {
        border-bottom: solid 1px #1575C5 !important;
        //TODO: Why doesn't the border radius work?!?
        border-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.first {
        border-top: solid 1px #1575C5 !important;
        border-radius: 10px;
      }
    }
  }
}

.scroll-box {
  height: 50vh;
  overflow: scroll;
}

.fixed_header {
  table {
  text-align: left;
  position: relative;
  height: 50px;
  }
  th {
  background-color: map-get($theme-colors, "verylightgray");
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
}
}

#captive-name-search {
  top: 30px !important;
}

#client-name-search {
  top: 30px !important;
}

#captive-code-search {
  top: 30px !important;
}

