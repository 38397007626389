@import "../../variables";

.simple-card {
    border-left: 3px solid map-get($theme-colors, "bordergray");
    border-radius: 0px;

    &.active {
        border-color: map-get($theme-colors, "blue");
    }

    &.selected {
        border-color: map-get($theme-colors, "secondary");
        background-color: white;

    }

    .card-title {
        margin-bottom: 0.25rem;
        font-size: 20;
    }

    .card-text {
        margin-bottom: 0.25rem;
        font-size: 14;
    }

    a.card-action {
        color: map-get($theme-colors, "darkblue");
        font-size: 10;
    }

    // button.card-action {

    // }
}