@import "../variables";

.ClientList {
  .column-small-width {
    max-width: 75px;
    white-space: break-spaces;
    text-align: center;
  }

  .column-entity {
    max-width: 100px;
    min-width: 100px;
    width: 100px;
  }

  #entities-search {
    width: 90px;
    margin-right: 10px;
  }

  .column-client-name {
    max-width: 160px;
    min-width: 160px;
    width: 160px;
  }

  .client-name-search {
    //width: 150px;
    margin-right: 10px;
    input {
      padding-left: 0.75rem;
    }
  }

  .column-captive-name {
    max-width: 160px;
    min-width: 160px;
    width: 160px;
  }

  .captive-name-search {
    //width: 150px;
    margin-right: 10px;
    input {
      padding-left: 0.75rem;
    }
  }

  .column-captive-code {
    max-width: 120px;
    min-width: 120px;
    width: 120px;
  }

  .captive-code-search {
    //width: 110px;
    margin-right: 10px;
    input {
      padding-left: 0.75rem;
    }
  }

  .column-status {
    max-width: 120px;
    min-width: 120px;
    width: 120px;
  }

  .status-search {
    width: 110px;
    margin-right: 10px;
    input {
      padding-left: 0.75rem;
    }
  }

  .search-form {
    margin-top: 1.5rem;

    #bank {
      margin-left: 57px;
    }
  }
}
