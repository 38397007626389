@import "../../variables";

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  background-color: map-get($theme-colors, "verydarkgray");
  color: #fff;
  margin-left: -$sidebar-width;
  transition: all 0.5s;
  text-align: left;
  padding: 20px 0px;

  &.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  & h3 {
    font-size: 18px;
    letter-spacing: 3.6px;
    padding: 10px;
    width: 200px;
  }

  & .side-menu {
    height: calc(100vh - 130px);

    & li {
      text-align: left;
      border-bottom: $sidebar-border;

      i {
        // display: inline-flex;
        margin-right: 7px;
        // vertical-align: middle;
        display: flex;
        align-items: center;

        svg path {
          fill: #fff;
        }
      }

      &:first-of-type {
        border-top: $sidebar-border;
      }

      & a {
        padding: 10px 20px;

        &.active {
          background-color: map-get($theme-colors, "verydarkgray");
        }
      }
    }

    & .menu-open {
      & a.dropdown-toggle::after {
        transform: rotate(180deg);
      }
    }

    & .items-menu {
      & li {
        border: none;
      }
    }

    & .submenu {
      & li {
        border: none;
      }

      & a {
        margin-left: 20px;
      }

      & .submenu-open {
        & a.dropdown-toggle::after {
          transform: rotate(180deg);
        }
      }
    }

    & .nav-item:hover {
      color: #fff;
      background: map-get($theme-colors, "darkgray");
    }

    & li a.dropdown-toggle::after {
      position: relative;
      float: right;
      top: 5px;
      transition-duration: 0.33s;
      transition-property: transform;
    }
  }

  svg:not(:root).svg-inline--fa {
    min-width: 1.2em;
    margin-left: -5px;
  }
}

// .side-menu::-webkit-scrollbar {
//   width: 10px;
// }

// .side-menu::-webkit-scrollbar-thumb {
//   background: #5466b3;
//   border-radius: 3px;
// }

// .side-menu::-webkit-scrollbar-thumb:hover {
//   background: #3a4c99;
// }

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .toggle-sidebar {
    position: absolute;
    left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease all;
  }

  .sidebar {
    transition: all 0.5s;
    position: absolute;
    z-index: 999;

    &.is-open {
      transition: 0.5s;
      margin-left: 0;
    }
  }
}
