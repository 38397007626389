@import "../../../scss/variables";

.SummaryBox {
  margin: 20px 0px;
  border-left: 5px solid map-get($theme-colors, "blue");

  .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .icon {
    padding: 1rem 0.5rem;
    display: inline-block;
    cursor: pointer;
    fill: #0070b9;
  }
  .icon:hover {
    fill: red;
  }

  .description {
    padding: 0.75rem 0.5rem;
    text-align: left;
    vertical-align: middle;
    font-size: 1rem;
  }
}
