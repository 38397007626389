@import "../../variables";

.close-button {
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: -40px;
  right: 0;
  min-width: max-content !important;
  margin-top: -5px;
}

.close-button:hover {
  cursor: pointer;
}

.alert-icon-spin {
  animation: spin 0.5s forwards;
  animation-delay: 0.5s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

.alert-iconWarning-title {
  letter-spacing: 1.5px !important;
  font-size: 24px;
}

.alert-iconWarning-button {
  width: 250px;
  height: 40px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
}

.alert-iconWarning-text {
  letter-spacing: 1.5px !important;
  font-size: 18px;
  line-height: 24.51px;
  padding-top: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
