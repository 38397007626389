@import "../../../scss/variables";

.DataTable {
  color: map-get($theme-colors, "black");

  .form-group {
    padding-right: 10px;
  }

  .form-icon {
    z-index: 0;
  }

  .react-bootstrap-table-pagination-list {
    z-index: 0;
  }

  .icon-action {
    svg path {
      fill: map-get($theme-colors, "darkgray");
    }
    cursor: pointer;
  }

  .download-text {
    font-size: 16px;
    color: #2972c8;
    font-style: normal;
  }

  .table-header-tooltip {
    margin: 10px 5px;
    padding: 10px;
    visibility: hidden;
    width: 350px;
    background-color: map-get($theme-colors, "verylightgray");
    color: map-get($theme-colors, "black");
    text-align: left;
    border-left: 3px solid map-get($theme-colors, "blue");
    position: absolute;
    font-size: 14px;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .table-header-tooltip-icon:hover .table-header-tooltip {
    visibility: visible;
    opacity: 1;
  }

  /* Arrow for tooltip */
  .table-header-tooltip::after {
    content: "";
    position: absolute;
    top: 0%;
    right: 100%;
    margin-top: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent map-get($theme-colors, "blue") transparent
      transparent;
  }

  .expand-cell {
    cursor: pointer;
    max-width: 20px;
    min-width: 20px;
  }

  .selection-cell {
    max-width: 20px;
    min-width: 20px;
  }

  td {
    max-width: 120px;
    min-width: 120px;
  }

  .CaptivesList {
    .react-bs-table-no-data {
      padding-left: 300px;
    }
  }
}
