@import "../../../scss/variables";

.PendingReleasesModal {
  .modal-content {
    width: 1000px;
  }

  .checkboxes {
    text-align: left;
    margin-left: 20px;
  }

  .icon-action {
    svg path {
      fill: map-get($theme-colors, "darkgray");
    }
    cursor: pointer;
  }

  @media only screen and (max-width: 1200px) {
    .modal-content {
      width: 100% !important;
    }
  }
}
