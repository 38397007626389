@import "../../variables";

.AddEditModal {
  h3 {
    color: map-get($theme-colors, "black");
    font-size: 18px;
    font-weight: 400;
  }

  // button {
  //   min-width: 125px;
  // }

  .close-button {
    min-width: max-content !important;
    margin-top: -5px;
  }

  .close.rbt-close {
    right: 0;
  }

  .header-tooltip {
    margin: 10px 5px;
    padding: 10px;
    visibility: hidden;
    width: 350px;
    background-color: map-get($theme-colors, "verylightgray");
    color: black;
    text-align: left;
    border-left: 3px solid map-get($theme-colors, "blue");
    position: absolute;
    font-size: 14px;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .header-tooltip-icon:hover .header-tooltip {
    visibility: visible;
    opacity: 1;
  }

  /* Arrow for tooltip */
  .header-tooltip::after {
    content: "";
    position: absolute;
    top: 0%;
    right: 100%;
    margin-top: 0px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent map-get($theme-colors, "blue") transparent
      transparent;
  }
}
