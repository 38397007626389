@import '../../variables';


  .progress-indicator .step {
    width: 200px;
  }

  .schedule-dropdown{
    p {
      font-size: 0.85rem;
    }

    box-shadow: none !important;

    .dropdown-toggle {
      display: block;
      font-weight: normal;
      color: map-get($theme-colors, "primary") !important;
      background-color: map-get($theme-colors, "verylightgray") !important;
      border-radius: 0px;
      border: 0px transparent!important;
      width: 420px;
      text-align: left;
    }

    .dropdown-menu .show {
      width: 420px;
    }
  }

  .btn-secondary:focus {
    box-shadow: none !important;
  }
