@import "../../../scss/variables";

.react-calendar {
    & abbr {
        text-decoration: none;
        cursor: pointer;
    }
      
    &__month-view {
        &__weekdays {
            text-align: center;
            text-transform: uppercase;
      
            &__weekday {
                text-decoration: none;
            }
          }

        &__days {

            &__day {
              color: map-get($theme-colors, "verylightgray");
              background-color: map-get($theme-colors, "green");

              &--weekend {
                background-color: map-get($theme-colors, "lightgray");
              }
      
              &--neighboringMonth {
                background-color: map-get($theme-colors, "lightgray");
              }
            }
          }
    }

    &__tile {
        border: 0.4rem solid map-get($theme-colors, "verylightgray");
        outline: none;
        text-align: center;
        height: 2.5rem;
        font-size: 0.75rem;

        &--now {
          border-style: solid;
          border-color: map-get($theme-colors, "blue");
          border-width: 0.25rem;
        }


    &:disabled {
        background-color: map-get($theme-colors, "darkgray");
      }
    }
  }